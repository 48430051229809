import React from 'react';
import SuccessCheckmark from "../../../../_components/icons/success-checkmark/SuccessCheckmark";
import {useLocation} from "react-router-dom";

function AccessGranted() {
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const appName = queryParams.get('app_name') || 'your app';


    return (
        <div className="d-flex justify-content-center align-items-center vh-100">
            <div className="card p-4 shadow-lg bg-glass card-sm">
                <div className="text-center my-4">
                    <h1>Success!</h1>
                    <SuccessCheckmark />
                    <p className={'mt-3'}>You can now close this window and return to <b>{appName}</b>.</p>
                </div>
            </div>
        </div>
    );
};

export default AccessGranted;

