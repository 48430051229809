import React, {useState} from 'react';
import './NavLayout.scss';
import NavSidebar from "../../nav/sidebar/NavSidebar";
import NavTopbar from "../../nav/top/NavTopbar";

interface IProps {
    children: React.ReactNode;
}

function NavLayout(props: IProps) {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <div className={'nav-layout-outer'}>
            <NavTopbar isSidebarOpen={isSidebarOpen} onBurgerClick={toggleSidebar}/>
            <div className={'nav-layout-inner'}>
                <div
                    className={`nav-sidebar-background ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}
                    onClick={() => setIsSidebarOpen(false)}
                />
                <div className={`sidebar-wrapper ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
                    <NavSidebar fullScreen={isSidebarOpen}/>
                </div>
                <div className={'nav-layout-content-outer'}>
                    <div className={'nav-layout-content-inner nav-content'}>
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NavLayout;
