import React, { forwardRef } from 'react';
import BootstrapButton from "../BootstrapButton";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    className?: string;
}

const PrimaryButtonSmall = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
    const { className, children, ...rest } = props;

    return (
        <BootstrapButton
            ref={ref}
            variant={'primary'}
            size={'sm'}
            className={className}
            {...rest}
        >
            {children}
        </BootstrapButton>
    );
});

export default PrimaryButtonSmall;
