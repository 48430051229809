import React from 'react';
import './NavTopbar.scss';
import {SecondaryButton} from "../../buttons";
import {Dropdown} from "react-bootstrap";
import authService from "../../../services/auth.service";
import {useNavigate} from "react-router";
import {useAuth} from "../../../app/modules/auth/AuthContext";
import {toast} from "react-toastify";

interface IProps {
    onBurgerClick: () => void;
    isSidebarOpen: boolean;
}

function NavTopbar({isSidebarOpen, onBurgerClick }: IProps) {
    return (
        <div className={'navbar-top'}>
            <div className={'nav-content nav-top-content'}>
                <div className={'navbar-top-container-left'}>
                    <div>
                        <SecondaryButton className={'menu-icon'} onClick={onBurgerClick}>
                            <i className={`fa-solid fa-angles-right ${isSidebarOpen && 'rotated'}`}></i>
                        </SecondaryButton>
                    </div>
                </div>
                <div className={'navbar-top-container-center'}>
                </div>
                <div className={'navbar-top-container-right'}>
                    <div className={'btn-group gap-1'}>
                        <ProfileButton />
                    </div>
                </div>
            </div>
        </div>
    );
}

function ProfileButton() {
    const navigate = useNavigate();
    const auth = useAuth();

    async function logout() {
        await authService.logout();
        await auth.reloadUser();
        navigate('/auth/login');
    }

    async function subscriptions() {
        navigate('/subscriptions');
    }

    async function accountSettings() {
        toast('Not implemented yet');
    }

    return (
        <Dropdown id={'user-dropdown'} >
            <Dropdown.Toggle as={SecondaryButton} className={'rounded-circle dropdown-no-icon'} style={{'pointerEvents': 'auto'}}>
                <i className={'fa-solid fa-user'} style={{'pointerEvents': 'none'}}></i>
            </Dropdown.Toggle>
            <Dropdown.Menu style={{'minWidth': '300px'}}>
                <Dropdown.Item onClick={accountSettings}>Account Settings</Dropdown.Item>
                <Dropdown.Item onClick={subscriptions}>My Subscription</Dropdown.Item>
                <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default NavTopbar;