import React from 'react';
import {Outlet, Route, Routes} from "react-router-dom";
import AuthLayout from "../../../_components/layouts/auth";
import AuthenticateApp from "./authenticate-app/AuthenticateApp";
import AccessGranted from "./access-granted/AccessGranted";

function OAuthRoutes() {
    return (
        <Routes>
            <Route element={<AuthLayout> <Outlet /> </AuthLayout>}>
                <Route path={'/'} element={ <AuthenticateApp />} />
                <Route path={'/success'} element={ <AccessGranted /> } />
            </Route>
        </Routes>
    );
}

export default OAuthRoutes;