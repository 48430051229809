import axios, { AxiosRequestConfig, AxiosResponse} from "axios";
import authService from "../auth.service";

function isDateString(value: string): boolean {
    // This regex matches ISO 8601 date strings
    const isoDatePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
    return isoDatePattern.test(value);
}

function convertDateStringsToDates(input: any): any {
    if (input === null || input === undefined) return input;

    if (typeof input === 'string' && isDateString(input)) {
        return new Date(input);
    }

    if (Array.isArray(input)) {
        return input.map(element => convertDateStringsToDates(element));
    }

    if (typeof input === 'object') {
        const result: { [key: string]: any } = {};
        for (const key in input) {
            if (Object.prototype.hasOwnProperty.call(input, key)) {
                result[key] = convertDateStringsToDates(input[key]);
            }
        }
        return result;
    }

    return input;
}

axios.interceptors.response.use((response: AxiosResponse) => {
    response.data = convertDateStringsToDates(response.data);
    return response;
}, (error) => {
    // Handle error
    return Promise.reject(error);
});


export default class authAxios {
    static async get<T = any, R = AxiosResponse<T, any>, D = any>(url: string, config?: AxiosRequestConfig<any> | undefined): Promise<AxiosResponse<T>> {
        return (await handleAuth(async () => {
            return (await axios.get<T, R, D>(url,
              { ...config,
                  withCredentials: true
              }));
        }));
    }

    static async post<T = any, R = AxiosResponse<T, any>, D = any>(url: string, data?: any, config?: AxiosRequestConfig<any> | undefined): Promise<AxiosResponse<T>> {
        return (await handleAuth(async () => {
            return (await axios.post<T, R, D>(url, data,
              { ...config,
                  withCredentials: true
              }));
        }));
    }

    static async patch<T = any, R = AxiosResponse<T, any>, D = any>(url: string, data?: any, config?: AxiosRequestConfig<any> | undefined): Promise<AxiosResponse<T>> {
        return (await handleAuth(async () => {
            return (await axios.patch<T, R, D>(url, data,
              { ...config,
                  withCredentials: true
              }));
        }));
    }

    static async put<T = any, R = AxiosResponse<T, any>, D = any>(url: string, data?: D | undefined, config?: AxiosRequestConfig<D> | undefined): Promise<AxiosResponse<T>> {
        return (await handleAuth(async () => {
            return (await axios.put<T, R, D>(url, data,
              { ...config,
                  withCredentials: true
              }));
        }));
    }

    static async delete<T = any, R = AxiosResponse<T, any>, D = any>(url: string, config?: AxiosRequestConfig<D> | undefined): Promise<AxiosResponse<T>> {
        return (await handleAuth(async () => {
            return (await axios.delete<T, R, D>(url,
              { ...config,
                withCredentials: true
            }));
        }));
    }
}

async function handleAuth<T>(req: Function): Promise<AxiosResponse<T>> {
    try {
        return (await req());
    } catch(err: any) {
        if(err?.response?.status) {
            const statusCode = err.response.status;
            if(statusCode === 401) {
                const response = await authService.getAccessToken();
                try {
                    return (await req());
                } catch(err: any) {
                    const statusCode = err.response.status;
                    if(statusCode === 401) {
                        try {
                            await authService.logout();
                        } catch(err: any) {
                            if(err.response.status !== 401) {
                                console.log(err.response);
                            }
                        } finally {
                            window.location.href = "/auth/login";
                        }
                    }
                    throw err;
                }
            }
        }
        throw err;
    }
}