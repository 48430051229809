import React from 'react';

function ChangelogPage() {
    return (
        <div>
            <h1>Changelog</h1>
            <p>
                This is still work in progress. However, once finished, you will be able to see any changes made
                within updates neatly listed here.
            </p>
            <div className={'d-flex justify-content-center align-items-center mt-5'}>
                <div className={'w-50'}>
                    <img src={'/images/under-construction.svg'} alt={'Under Construction'} width={'100%'}/>
                </div>
            </div>
        </div>
    );
}

export default ChangelogPage;