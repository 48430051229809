import {createContext, useContext} from "react";
import {ILinkedAccount} from "../../../../models/dto/LinkedAccount";

export interface ILinkedAccountsContext {
    linkedAccounts: null | ILinkedAccount[];
    reload: () => Promise<void>;
}

const LinkedAccountsContext = createContext<ILinkedAccountsContext | null>(null);

export function useLinkedAccounts(): null | ILinkedAccount[] {
    const context = useContext(LinkedAccountsContext);
    if (context == null) {
        throw new Error('useLinkedAccounts must be used within a LinkedAccountsProvider');
    }
    return context.linkedAccounts;
}

export function useLinkedAccountsReload(): () => Promise<void> {
    const context = useContext(LinkedAccountsContext);
    if (context == null) {
        throw new Error('reloadLinkedAccounts must be used within a LinkedAccountsProvider');
    }
    return context.reload;
}

export default LinkedAccountsContext;
