import React from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import OAuthRoutes from "../pages/oauth";
import Dashboard from "../pages/dashboard";
import SubscriptionsRoutes from "../pages/subscriptions/SubscriptionsRoutes";
import DownloadsRoutes from "../pages/downloads/DownloadsRoutes";
import ChangelogRoutes from "../pages/changelog/ChangelogRoutes";

function PrivateRoutes() {
    return (
        <Routes>
            <Route path={'/oauth/*'} element={ <OAuthRoutes />} />
            <Route path={'/dashboard'} element={ <Dashboard /> } />
            <Route path={'/downloads'} element={ <DownloadsRoutes />} />
            <Route path={'/changelog'} element={ <ChangelogRoutes /> } />
            <Route path={'/subscriptions/*'} element={ <SubscriptionsRoutes /> } />
            <Route path={'*'} element={ <Navigate to={'/dashboard'} /> } />
        </Routes>
    );
}

export default PrivateRoutes;
