import * as yup from 'yup';

export interface IPermission {
    permission: string[];
}

export default class Permission implements IPermission {
    permission: string[];

    public static readonly schema = yup.object().shape({
        permission: yup.array().of(yup.string().required()).required(),
    });

    constructor(permission: string | IPermission) {
        if (typeof permission === 'string') {
            this.permission = permission.toLowerCase().split('.');
        } else {
            Permission.schema.validateSync(permission);
            this.permission = permission.permission;
        }
    }

    check(permission: string | Permission): boolean {
        if (typeof permission === 'string') {
            permission = new Permission(permission);
        }

        for (let i = 0; i < this.permission.length; i++) {
            if (this.permission[i] !== permission.permission[i]) {
                return false;
            }
        }
        return true;
    }
}
