import React, {useEffect, useState} from 'react';
import {PrimaryButtonSmall} from "../../../../_components/buttons";
import {LoadingSpinner} from "../../../../_components/spinners";
import LinkedAccountsList from "./linked-accounts-list/LinkedAccountsList";
import './LinkedAccountsCard.scss';
import useLinkedAccounts from "./provider";
import LinkAccountModal from "../link-account/LinkAccountModal";

function LinkedAccountsCard() {
    const linkedAccounts = useLinkedAccounts();
    const showList = linkedAccounts !== null && linkedAccounts.length > 0;
    const showLoader = linkedAccounts === null;
    const showEmpty = linkedAccounts !== null && linkedAccounts.length <= 0;

    return (
        <div className={'card'}>
            <LinkedAccountsCardHeader />

            { showList && <LinkedAccountsList data={linkedAccounts}/> }

            { showLoader &&
                <div className={'linked-accounts-loading-spinner'}>
                    <LoadingSpinner />
                </div>
            }

            { showEmpty &&
                <div className={'card-body'}>
                    {linkedAccounts !== null && linkedAccounts.length <= 0 &&
                        <p>Currently, there are no accounts added to your account.</p>}
                    <p className={'mt-3'}>If you already have a subscription on another platform (e.g. Patreon), you
                        can
                        add
                        it here to unlock your
                        benefits.</p>
                </div>
            }
        </div>
    );
}

function LinkedAccountsCardHeader() {
    const [showLinkAccountModal, setShowLinkAccountModal] = useState<boolean>(false);
    return (
        <div className={'card-header'}>
            <LinkAccountModal show={showLinkAccountModal} handleClose={() => setShowLinkAccountModal(false)}/>
            <div className={'d-flex align-items-center'}>
                <div className={'flex-grow-1'}>Linked Accounts</div>
                <div>
                    <PrimaryButtonSmall
                        onClick={() => setShowLinkAccountModal(true)}
                    >
                        Add Account
                    </PrimaryButtonSmall>
                </div>
            </div>
        </div>
    )
}

export default LinkedAccountsCard;