import React from 'react';
import './bootstrap.css';
import './App.css';
import {AuthProvider} from "./app/modules/auth";
import AppRoutes from "./app/routing/AppRoutes";
import {ThemeProvider} from "./app/modules/theme";
import MainLayout from "./_components/layouts/main";
import Toastify from "./_components/toastify/Toastify";

function App() {
  return (
      <React.StrictMode>
          <MainLayout>
              <ThemeProvider>
                  <AuthProvider>
                      <AppRoutes />
                  </AuthProvider>
              </ThemeProvider>
          </MainLayout>
          <Toastify />
      </React.StrictMode>
  );
}

export default App;
