import React from 'react'

function LoadingSpinner({ text }: { text?: string }) {
    if(text === undefined) text = 'Loading...';
    if(text) text = ' ' + text;

    return (
        <div className={`d-flex justify-content-center align-items-center`}>
            <span className='spinner-border spinner-border-sm align-middle'></span>
            <span>&nbsp;{text}</span>
        </div>
    );
}

export default LoadingSpinner
