import React from 'react';
import {Outlet, Route, Routes} from "react-router-dom";
import Overview from "./overview/Overview";
import NavLayout from "../../../_components/layouts/nav/NavLayout";

function DashboardRoutes() {
    return (
        <Routes>
            <Route element={<NavLayout> <Outlet /> </NavLayout>}>
                <Route path={'/'} element={ <Overview />} />
            </Route>
        </Routes>
    );
}

export default DashboardRoutes;
