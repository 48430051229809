import React from 'react';
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import './Toastify.scss';

const CloseButton = ({ closeToast }: any) => (
    <div className={'toastify-close-button-container'}>
        <i
            className="fa-solid fa-xmark"
            onClick={closeToast}
        />
    </div>
);


function Toastify() {
    return (
        <div className={'toastify-container'}>
            <ToastContainer
                hideProgressBar={true}
                position="top-center"
                autoClose={2000}
                pauseOnHover={false}
                closeButton={CloseButton}
            />
        </div>
    );
}

export default Toastify;