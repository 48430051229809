
export default class URLBuilder {
    private url: string;
    private params: { [key: string]: string } = {};

    constructor(url: string) {
        this.url = url;
    }

    set(key: string, value: string, encode=true) {
        if(encode) this.params[encodeURIComponent(key)] = encodeURIComponent(value);
        else this.params[key] = value;
        return this;
    }

    build() {
        let url = this.url;
        if (Object.keys(this.params).length > 0) {
            url += '?';
            for (const key in this.params) {
                url += `${key}=${this.params[key]}&`;
            }
            url = url.slice(0, -1); // Remove the trailing '&'
        }
        return url;
    }
}
