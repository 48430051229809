import './FailureCheckmark.scss'

export default function FailureCheckmark() {
    return (
        <svg
            className="ft-red-cross"
            xmlns="http://www.w3.org/2000/svg"
            height="100"
            width="100"
            viewBox="0 0 48 48"
            aria-hidden="true"
        >
            <circle className="circle" cx="24" cy="24" r="22" />
            <path
                className="cross"
                fill="none"
                stroke="#FFF"
                strokeWidth="6"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                d="M16 16l16 16M32 16L16 32"
            />
        </svg>
    )
}
