import React, {createContext} from "react";
import User from "../../models/User";
import ILoginDto from "../../models/dto/LoginDto";
import IRegisterDto from "../../models/dto/RegisterDto";

export interface IAuthContext {
    user: User | null;
    reloadUser: () => Promise<void>;
    login: (loginData: ILoginDto) => Promise<User>;
    register: (registerData: IRegisterDto) => Promise<User>;
}

const AuthContext = createContext<IAuthContext | undefined>(undefined);


export function useUser(): User | null {
    const context = React.useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useUser must be used within an AuthProvider');
    }

    return context.user;
}

export function useAuth() {
    const context = React.useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
}

export default AuthContext;
