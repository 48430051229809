import React, { useState } from 'react';
import { Formik, Field, Form, FieldProps } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router';
import { DarkButton, PrimaryButtonLarge } from '../../../../_components/buttons';
import {useAuth} from "../../../modules/auth/AuthContext";

interface RegisterFormValues {
    email: string;
    password: string;
    confirmPassword: string;
}

const Register: React.FC = () => {
    const initialValues: RegisterFormValues = {
        email: '',
        password: '',
        confirmPassword: '',
    };

    const navigate = useNavigate();
    const authContext = useAuth();
    const [loading, setLoading] = useState(false);

    const validationSchema = Yup.object({
        email: Yup.string()
            .email('Invalid email address')
            .required('Email is required'),
        password: Yup.string()
            .min(6, 'Password must be at least 6 characters')
            .required('Password is required'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password')], 'Passwords must match')
            .required('Confirm Password is required'),
    });

    const handleSubmit = async (
        values: RegisterFormValues,
        { setStatus, setSubmitting }: any
    ) => {
        setLoading(true);
        try {
            await authContext.register({ email: values.email, password: values.password });
            navigate('/');
        } catch (error: any) {
            if (error && error.response && error.response.data && error.response.data.message) {
                setStatus(error.response.data.message);
            } else {
                setStatus('Service currently unavailable. Please try again later.');
            }
            setSubmitting(false);
            setLoading(false);
        }
    };

    return (
        <div className="d-flex justify-content-center align-items-center vh-100">
            <div className="card p-4 shadow-lg bg-glass card-sm">
                <h1 className="text-center mb-4">Create Account</h1>

                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ isSubmitting, status }) => (
                        <Form>
                            {status && (
                                <div className="mb-lg-15 alert alert-danger">
                                    <div className="alert-text font-weight-bold">{status}</div>
                                </div>
                            )}

                            <div className="form-group mb-3">
                                <label htmlFor="email" className="form-label">
                                    Email
                                </label>
                                <Field name="email">
                                    {({ field, meta }: FieldProps) => (
                                        <div>
                                            <input
                                                type="email"
                                                id="email"
                                                {...field}
                                                className={`form-control ${
                                                    meta.touched && meta.error ? 'is-invalid' : ''
                                                }`}
                                            />
                                            {meta.touched && meta.error && (
                                                <div className="text-danger mt-1">{meta.error}</div>
                                            )}
                                        </div>
                                    )}
                                </Field>
                            </div>

                            <div className="form-group mb-3">
                                <label htmlFor="password" className="form-label">
                                    Password
                                </label>
                                <Field name="password">
                                    {({ field, meta }: FieldProps) => (
                                        <div>
                                            <input
                                                type="password"
                                                id="password"
                                                {...field}
                                                className={`form-control ${
                                                    meta.touched && meta.error ? 'is-invalid' : ''
                                                }`}
                                            />
                                            {meta.touched && meta.error && (
                                                <div className="text-danger mt-1">{meta.error}</div>
                                            )}
                                        </div>
                                    )}
                                </Field>
                            </div>

                            <div className="form-group mb-3">
                                <label htmlFor="confirmPassword" className="form-label">
                                    Confirm Password
                                </label>
                                <Field name="confirmPassword">
                                    {({ field, meta }: FieldProps) => (
                                        <div>
                                            <input
                                                type="password"
                                                id="confirmPassword"
                                                {...field}
                                                className={`form-control ${
                                                    meta.touched && meta.error ? 'is-invalid' : ''
                                                }`}
                                            />
                                            {meta.touched && meta.error && (
                                                <div className="text-danger mt-1">{meta.error}</div>
                                            )}
                                        </div>
                                    )}
                                </Field>
                            </div>

                            <div className="d-grid gap-2">
                                <PrimaryButtonLarge
                                    type="submit"
                                    disabled={isSubmitting || loading}
                                >
                                    {isSubmitting || loading ? 'Creating Account...' : 'Create Account'}
                                </PrimaryButtonLarge>

                                <div className="or-divider">
                                    <span className="line"></span>
                                    <p className="text-secondary fw-bolder text-center mb-0 or-text">
                                        OR
                                    </p>
                                    <span className="line"></span>
                                </div>

                                <DarkButton
                                    disabled={isSubmitting || loading}
                                    onClick={() => navigate('/auth/login')}
                                >
                                    Already have an account? Login
                                </DarkButton>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default Register;
