import React from 'react';
import {ILinkedAccount} from "../../../../models/dto/LinkedAccount";
import LinkedAccount from "../linked-account/LinkedAccount";
import './LinkedAccountsList.scss';

interface IProps {
    data: ILinkedAccount[] | null;
}

function LinkedAccountsList({ data }: IProps ) {

    if(data == null) return null;
    return (
        <div className={'linked-accounts-list'}>
            {data.map((account) => (
                <>
                    <LinkedAccount data={account} key={account.id}/>
                </>
            ))}
        </div>
    );
}

export default LinkedAccountsList;
