import AuthContext from "./AuthContext";
import {useEffect, useState} from "react";
import User, {IUser} from "../../models/User";
import ILoginDto from "../../models/dto/LoginDto";
import authService from "../../../services/auth.service";
import IRegisterDto from "../../models/dto/RegisterDto";
import Subscription from "../../models/Subscription";

interface IProps {
    children: React.ReactNode;
}

function AuthProvider(props: IProps) {
    const [user, setUser] = useState<User | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    async function login(loginData: ILoginDto): Promise<IUser> {
        const response = await authService.login(loginData);
        setUser(new User(response.data));
        return response.data;
    }

    async function register(registerData: IRegisterDto) {
        const response = await authService.register(registerData);
        return response.data;
    }

    async function reloadUser() {
        try {
            const result = await authService.getProfile();
            setUser(result);
        } catch(err) {
            console.log(err);
            setUser(null);
        }
    }

    async function init() {
        return reloadUser();
    }

    useEffect(() => {
        init().then(
            () => {
                setLoading(false);
            }
        );
    }, []);

    if(loading) return ( <>Loading...</> );

    return (
        <AuthContext.Provider value={{
            user,
            reloadUser,
            login,
            register
        }}>
            {props.children}
        </AuthContext.Provider>
    );
}

export default AuthProvider;