import patreonService from "./patreon.service";
import authAxios from "./util/authAxios";
import {ILinkedAccount} from "../app/models/dto/LinkedAccount";


class PlatformsService {
    patreon = patreonService;

    async getLinkedAccounts(): Promise<ILinkedAccount[]> {
        return (await authAxios.get<any[]>(`${process.env.REACT_APP_API_BASE_URL}/platforms/patreon`)).data;
    }

    async unlinkAccount(type: string, id: string) {
        await authAxios.post(`${process.env.REACT_APP_API_BASE_URL}/platforms/${type}/${id}/unlink`);
    }
}

const platformsService = new PlatformsService();
export default platformsService;
