import React from 'react';
import { Formik, Field, Form, FieldProps } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useLocation } from 'react-router';
import { PrimaryButtonLarge, DarkButton } from '../../../../_components/buttons';
import ILoginDto from '../../../models/dto/LoginDto';
import { useAuth } from "../../../modules/auth/AuthContext";
import { clsx } from "../../../../_components/util";

const validationSchema = Yup.object({
    email: Yup.string()
        .email('Invalid email address')
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Email is required'),
    password: Yup.string()
        .min(6, 'Password must be at least 6 characters')
        .max(50, 'Maximum 50 symbols')
        .required('Password is required'),
});

const Login: React.FC = () => {
    const authContext = useAuth();
    const initialValues: ILoginDto = { email: '', password: '' };
    const navigate = useNavigate();
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    const redirectTo = searchParams.get('redirect') || '/';

    const handleSubmit = async (values: ILoginDto, { setStatus, setSubmitting }: any) => {
        try {
            setSubmitting(true);
            await authContext.login({ email: values.email, password: values.password });

            navigate(redirectTo);
        } catch (error: any) {
            if (error && error.response && error.response.data && error.response.data.message) {
                setStatus(error.response.data.message);
            } else {
                console.log(error);
                setStatus('Service is currently unavailable. Please try again later.');
            }
            setSubmitting(false);
        }
    };

    return (
        <div className="d-flex justify-content-center align-items-center vh-100">
            <div className="card p-4 shadow-lg bg-glass card-sm">
                <h1 className="text-center mb-4">Login</h1>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ isSubmitting, status }) => (
                        <Form>
                            {status && (
                                <div className='mb-lg-15 alert alert-danger'>
                                    <div className='alert-text font-weight-bold'>{status}</div>
                                </div>
                            )}

                            <div className="form-group mb-3">
                                <label htmlFor="email" className="form-label">Email</label>
                                <Field name="email">
                                    {({ field, meta }: FieldProps) => (
                                        <div>
                                            <input
                                                type="email"
                                                id="email"
                                                {...field}
                                                className={clsx('form-control', {
                                                    'is-invalid': meta.touched && meta.error,
                                                    'is-valid': meta.touched && !meta.error,
                                                })}
                                            />
                                            {meta.touched && meta.error && (
                                                <div className="text-danger mt-1">{meta.error}</div>
                                            )}
                                        </div>
                                    )}
                                </Field>
                            </div>

                            <div className="form-group mb-3">
                                <label htmlFor="password" className="form-label">Password</label>
                                <Field name="password">
                                    {({ field, meta }: FieldProps) => (
                                        <div>
                                            <input
                                                type="password"
                                                id="password"
                                                {...field}
                                                className={clsx('form-control', {
                                                    'is-invalid': meta.touched && meta.error,
                                                    'is-valid': meta.touched && !meta.error,
                                                })}
                                            />
                                            {meta.touched && meta.error && (
                                                <div className="text-danger mt-1">{meta.error}</div>
                                            )}
                                        </div>
                                    )}
                                </Field>
                            </div>

                            <div className="d-grid gap-2">
                                <PrimaryButtonLarge
                                    type="submit"
                                    disabled={isSubmitting}>
                                    {isSubmitting ? 'Logging in...' : 'Login'}
                                </PrimaryButtonLarge>

                                <div className="or-divider">
                                    <span className="line"></span>
                                    <p className="text-secondary fw-bolder text-center mb-0 or-text">OR</p>
                                    <span className="line"></span>
                                </div>

                                <div className="d-grid gap-2">
                                    <DarkButton
                                        disabled={isSubmitting}
                                        onClick={() => navigate('/auth/register')}  // Use navigate for redirect without reload
                                    >
                                        Create an account instead
                                    </DarkButton>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default Login;
