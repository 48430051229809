import React, { useState, useEffect } from 'react';
import './LoadingOverlay.scss';

interface IProps {
    children: React.ReactNode;
    showing: boolean;
}

function LoadingOverlay({ children, showing }: IProps) {
    const [fadeOut, setFadeOut] = useState(false);

    useEffect(() => {
        if (!showing) {
            setFadeOut(true);
        } else {
            setFadeOut(false);
        }
    }, [showing]);

    return (
        <div className={`overlay-outer ${fadeOut ? 'fade-out' : ''}`}>
            <div className={'overlay-inner'}>
                <div className={'d-flex align-items-center justify-content-center flex-column gap-3'}>
                    { children }
                </div>
            </div>
        </div>
    );
}

export default LoadingOverlay;
