import * as yup from 'yup';
import Permission from "./util/Permission";

export interface ISubscription {
    tier: string;
    source: string;
    sourceRef: string | null;
    imageUrl: string | null;
    started: Date;
    expires: Date;
    permissions: Permission[];
}

export default class Subscription implements ISubscription {
    tier: string;
    source: string;
    sourceRef: string | null;
    imageUrl: string | null;
    started: Date;
    expires: Date;
    permissions: Permission[];

    public static readonly schema = yup.object().shape({
        tier: yup.string().required(),
        source: yup.string().required(),
        sourceRef: yup.string().nullable().required(),
        started: yup.date().required(),
        expires: yup.date().required(),
        permissions: yup.array().of(yup.object().required()).default([]).required(),
        imageUrl: yup.string().nullable().required(),
    });

    constructor(subscription: ISubscription) {
        Subscription.schema.validateSync(subscription);

        this.tier = subscription.tier;
        this.source = subscription.source;
        this.sourceRef = subscription.sourceRef;
        this.imageUrl = subscription.imageUrl;
        this.started = subscription.started;
        this.expires = subscription.expires;
        this.permissions = subscription.permissions;
    }

    checkPermission(permission: string | Permission): boolean {
        if(typeof permission === 'string') {
            permission = new Permission(permission);
        }

        for(const perm of this.permissions) {
            if(perm.check(permission)) {
                return true;
            }
        }

        return false;
    }
}
