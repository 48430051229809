import React from 'react';
import {Outlet, Route, Routes} from "react-router-dom";
import NavLayout from "../../../_components/layouts/nav/NavLayout";
import ChangelogPage from "./ChangelogPage";

function ChangelogRoutes() {
    return (
        <Routes>
            <Route element={<NavLayout> <Outlet /> </NavLayout>}>
                <Route path="/" element={<ChangelogPage />} />
            </Route>
        </Routes>
    );
}

export default ChangelogRoutes;