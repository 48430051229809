import React from 'react';
import {Outlet, Route, Routes} from "react-router-dom";
import Login from "./login/Login";
import Register from "./register/Register";
import AuthLayout from "../../../_components/layouts/auth";
import './AuthRoutes.scss';

function AuthRoutes() {
    return (
        <Routes>
            <Route element={<AuthLayout> <Outlet /> </AuthLayout>}>
                <Route path={'/login'} element={ <Login />} />
                <Route path={'/register'} element={ <Register />} />
            </Route>
        </Routes>
    );
}

export default AuthRoutes;