import React from 'react';
import {Routes, BrowserRouter, Route} from 'react-router-dom'
import {useUser} from "../modules/auth";
import GuestRoutes from "./GuestRoutes";
import PrivateRoutes from "./PrivateRoutes";

function AppRoutes() {
    const user = useUser();

    return (
        <BrowserRouter>
            <Routes>
                { user === null &&
                    <Route path='*'  element={ <GuestRoutes /> } />
                }
                { user !== null &&
                    <Route path='*'  element={ <PrivateRoutes /> } />
                }
            </Routes>
        </BrowserRouter>
    );
}
export default AppRoutes;