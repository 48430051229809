import React from 'react';
import {Outlet, Route, Routes} from "react-router-dom";
import DownloadsPage from "./DownloadsPage";
import NavLayout from "../../../_components/layouts/nav/NavLayout";

function DownloadsRoutes() {
    return (
        <Routes>
            <Route element={<NavLayout> <Outlet /> </NavLayout>}>
                <Route path="/" element={<DownloadsPage />} />
            </Route>
        </Routes>
    );
}

export default DownloadsRoutes;