import authAxios from "../util/authAxios";


class OAuthService {
    async authorize(clientId: string, responseType: string) {
        const params = new URLSearchParams({
            client_id: clientId,
            response_type: responseType,
        });
        const response = await authAxios.get(`${process.env.REACT_APP_API_BASE_URL}/oauth/authorize?${params.toString()}`);
        return response.data;
    }
}

const oauthService = new OAuthService();
export default oauthService;
