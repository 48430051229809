
export default function clsx(...args: (string | Record<string, boolean | null | undefined | string | number>)[]): string {
    return args
        .map(arg => {
            if (typeof arg === "string" || typeof arg === "number") {
                return arg; // Always include string or number values
            } else if (typeof arg === "object" && arg !== null) {
                return Object.keys(arg)
                    .filter(key => Boolean(arg[key])) // Filter out keys with falsy values
                    .join(" ");
            }
            return ""; // If argument is not a valid object or string, return empty string
        })
        .filter(Boolean) // Remove any empty strings or falsy values
        .join(" "); // Join valid class names with spaces
}
