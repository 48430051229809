import React from "react";
import './MainLayout.scss';

interface IProps {
    children: React.ReactNode;
}

function MainLayout(props: IProps) {
    return (
        <div className={'main-layout'}>
            {props.children}
        </div>
    );
}

export default MainLayout;