import React from 'react';
import './NavSidebar.scss';
import {clsx} from "../../util";
import {useLocation} from 'react-router-dom';
import {useNavigate} from "react-router";
import {useUser} from "../../../app/modules/auth";
import {LoadingSpinner} from "../../spinners";
import Subscription from "../../../app/models/Subscription";
import {InfoButton, PrimaryButton, WarningButton} from "../../buttons";

const elements = [
    {
        "title": "Dashboard",
        "link": "/dashboard"
    },
    {
        "title": "Downloads",
        "link": "/downloads"
    },
    {
        "title": "Changelog",
        "link": "/changelog"
    },
    {
        "title": "Wiki",
        "link": "/wiki",
        "external": true
    }
]

function NavSidebar({ fullScreen }: { fullScreen?: boolean }) {
    const location = useLocation();
    const navigate = useNavigate();

    return (
        <div className={'sidebar'}>
            <UserProfile />
            { elements.map((element) => {
                return (
                    <div
                        key={element.link} // Add key for each element
                        className={clsx(`sidebar-element`, {
                            'sidebar-element-active': location.pathname.toLowerCase() === element.link.toLowerCase()
                        })}
                        onClick={() => navigate(element.link)}
                    >
                        { element.title }
                        { element.external &&
                            <span className={'mx-1'}>
                                <i className="fa-solid fa-external-link small color-dark"></i>
                            </span>
                        }
                    </div>
                )
            })}
        </div>
    );
}

function UserProfile() {
    const user = useUser();

    if(user === null) return (<LoadingSpinner />);

    return (
        <div className={'w-100'}>
            <UserSubscription subscription={user.subscription} />
        </div>
    )
}

function UserSubscription({ subscription }: { subscription: Subscription | null | undefined }) {
    const navigate = useNavigate();

    return (
        <div>
            { !subscription &&
                <>
                    <p>You do not have an active subscription.</p>
                    <WarningButton onClick={() => navigate('/subscriptions')}>
                        Activate Subscription
                    </WarningButton>
                </>
            }
            {subscription &&
                <>
                    <span className={'mx-2'}>
                        Current tier: <b>{subscription.tier}</b>
                    </span>
                </>
            }
        </div>
    )
}

export default NavSidebar;
