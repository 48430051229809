import React from 'react';
import ThemeContext from './ThemeContext';

interface IProps {
    children: React.ReactNode;
}

function ThemeProvider(props: IProps) {
    return (
        <ThemeContext.Provider value={undefined}>
            {props.children}
        </ThemeContext.Provider>
    );
}

export default ThemeProvider;