import React from 'react';
import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import AuthRoutes from "../pages/auth";
import OAuthRoutes from "../pages/oauth";

function GuestRoutes() {
    const location = useLocation();

    return (
        <Routes>
            <Route path={'/auth/*'} element={ <AuthRoutes />} />
            <Route
                path="*"
                element={
                    <Navigate
                        to={`/auth/login?redirect=${encodeURIComponent(location.pathname + location.search)}`}
                        replace
                    />
                }
            />
        </Routes>
    );
}

export default GuestRoutes;
