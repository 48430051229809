import {OAuthClient} from "../../app/models/oauth/IOAuthClient";
import authAxios from "../util/authAxios";


class OAuthClientService {
    async get(clientId: string): Promise<OAuthClient> {
        const response = await authAxios.get(`${process.env.REACT_APP_API_BASE_URL}/oauth/clients/${clientId}`);
        return new OAuthClient(response.data);
    }
}

const oauthClientService = new OAuthClientService();
export default oauthClientService;
