import authAxios from "./util/authAxios";
import {ITryConnectResult} from "../app/models/dto/TryConnectResult";
import URLBuilder from "../_components/util/URLBuilder";

class PatreonService {
    async getLoginUrl(): Promise<string> {
        return new URLBuilder("https://www.patreon.com/oauth2/authorize")
            .set('response_type', 'code')
            .set('client_id', `${process.env.REACT_APP_PATREON_CLIENT_ID}`)
            .set('scope', `${process.env.REACT_APP_PATREON_API_SCOPE}`)
            .set('redirect_uri', `${process.env.REACT_APP_BASE_URL}/subscriptions`)
            .build();
    }

    async tryConnect(code: string): Promise<ITryConnectResult> {
        const response = await authAxios.post<ITryConnectResult>(`${process.env.REACT_APP_API_BASE_URL}/platforms/patreon/oauth/codes`, { code });
        return response.data;
    }
}

const patreonService = new PatreonService();
export default patreonService;
