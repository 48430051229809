
export interface IOAuthClient {
    _id?: string;
    name: string;
    secret: string;
    scopes: string[];
    created?: Date;
}

const scopeExplanations = {
    "genery": "administrate genery",
    "genery.use": "generate and fetch images through your profile",
    "profile": "administrate your entire profile, including E-Mail and subscriptions",
    "profile.read": "read your entire profile, including E-Mail and subscriptions",
    "profile.subscription": "administrate your subscriptions",
    "profile.subscription.read": "read your current subscription status",
}

export class OAuthClient implements IOAuthClient {
    _id?: string;
    name: string;
    secret: string;
    scopes: string[];
    created?: Date;
    explanations: string[];

    constructor(client: IOAuthClient) {
        this.name = client.name;
        this.secret = client.secret;
        this.scopes = client.scopes;
        this.explanations = OAuthClient.getExplanations(client.scopes);
    }

    static getExplanations(scopes: string[]) {
        return scopes.map(scope => {
            if(Object.keys(scopeExplanations).includes(scope)) {
                // @ts-ignore
                return `${scopeExplanations[scope]}`;
            } else {
                return `${scope}: no explanation available`;
            }
        });
    }
}
