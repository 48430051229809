import React from 'react';
import './AuthLayout.scss';

interface IProps {
    children: React.ReactNode;
}

function AuthLayout({ children }: IProps ) {
    return (
        <div className={'auth-layout'}>
            { children }
        </div>
    );
}

export default AuthLayout;