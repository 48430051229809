import React, {useState} from 'react';
import { Modal } from 'react-bootstrap';
import {SecondaryButton} from "../../../../_components/buttons";
import {LoadingSpinner} from "../../../../_components/spinners";
import {useNavigate} from "react-router";
import patreonService from "../../../../services/patreon.service";

interface IProps {
    show: boolean;
    handleClose: () => void;
}

function LinkAccountModal({ show, handleClose }: IProps) {
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);

    async function handlePatreonClicked() {
        setLoading(true);
        const url = await patreonService.getLoginUrl();
        console.log(url);

        window.location.href = url;
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Link Account</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Select a platform to import your memberships from:</p>
                <div className={'div-list-horizontal'}>
                    <PlatformButton
                        loading={loading}
                        img={'/logos/subscribestar.png'}
                        alt={'Subscribestar'}
                        disabled={true}
                    />
                    <PlatformButton
                        loading={loading}
                        img={'/logos/patreon.svg'}
                        alt={'Patreon'}
                        onClick={handlePatreonClicked}
                    />
                </div>
                <SecondaryButton onClick={handleClose}>Cancel</SecondaryButton>
            </Modal.Body>
        </Modal>
    );
}

function PlatformButton({ img, alt, loading, onClick, disabled }: { img: string, alt?: string, loading?: boolean, onClick?: () => void, disabled?: boolean }) {
    return (
        <div style={{
            height: '8em',
            width: '50%',
            flexGrow: 1
        }}>
            <button className={'btn btn-secondary w-100'} style={{
                    height: '7em',
                }}
                onClick={() => onClick && onClick()}
                disabled={loading || disabled}
            >
                { loading && <LoadingSpinner text={'Loading'} /> }
                { !loading &&
                    <img
                        src={img}
                        alt={alt}
                        style={{
                            height: '100%',
                            width: '100%',
                            objectFit: 'contain'
                        }}
                    />
                }
            </button>
        </div>
    )
}

export default LinkAccountModal;
