import React, { useEffect, useState, useRef } from 'react';
import LoadingOverlay from "../../../../_components/overlays/LoadingOverlay";
import patreonService from "../../../../services/patreon.service";
import FailureCheckmark from "../../../../_components/icons/failure-checkmark/FailureCheckmark";
import SuccessCheckmark from "../../../../_components/icons/success-checkmark/SuccessCheckmark";
import './CodeActivator.scss';
import {useLinkedAccountsReload} from "../linked-accounts/provider";
import {LoadingSpinner} from "../../../../_components/spinners";
import {DangerButton, SuccessButton} from "../../../../_components/buttons";
import {useAuth} from "../../../modules/auth/AuthContext";

const CodeActivator: React.FC = () => {
    const reloadLinkedAccounts = useLinkedAccountsReload();
    const query = new URLSearchParams(window.location.search);
    const codeFromQuery = query.get('code');
    const auth = useAuth();

    const [showing, setShowing] = useState<boolean>(false);
    const [successText, setSuccessText] = useState<string | null>(null);
    const [errorText, setErrorText] = useState<string | null>(null);

    const hasProcessedRef = useRef<boolean>(false);

    async function connectAccount(codeFromQuery: string) {
        try {
            const result = await patreonService.tryConnect(codeFromQuery);
            const { newSubscription, patreonUser } = result;

            if (newSubscription) {
                setSuccessText("Activated a new subscription!");
            } else if (patreonUser) {
                const username = patreonUser.username || patreonUser.email || 'Patreon User';
                setSuccessText(`${username} linked successfully!`);
            } else {
                setErrorText("Failed to link account.");
            }
        } catch (error: any) {
            const errorMessage = error?.response?.data?.message || "An unexpected error occurred.";
            setErrorText(errorMessage);
        }
    }

    useEffect(() => {
        if (codeFromQuery && !hasProcessedRef.current) {
            hasProcessedRef.current = true;
            setShowing(true);

            const url = new URL(window.location.href);
            url.searchParams.delete('code');
            url.searchParams.delete('state');
            window.history.replaceState({}, document.title, url.pathname + url.search);

            connectAccount(codeFromQuery).then(() => {
                reloadLinkedAccounts().then(() => console.log('Linked accounts reloaded!'));
                auth.reloadUser().then(() => console.log('User reloaded!'));
            });
        }
    }, [auth, codeFromQuery, reloadLinkedAccounts]);

    if (!showing && !successText && !errorText) {
        return null;
    }

    return (
        <LoadingOverlay showing={showing}>
            { !errorText && !successText && <LoadingSpinner text={'Linking account...'} /> }
            { errorText && (
                <>
                    <FailureCheckmark />
                    <p className='text-center'>{errorText}</p>
                    <div className={'w-50'}>
                        <DangerButton onClick={() => setShowing(false)}>Got it</DangerButton>
                    </div>
                </>
            )}
            { successText && (
                <>
                    <SuccessCheckmark />
                    <p>{successText}</p>
                    <div className={'w-50'}>
                        <SuccessButton onClick={() => setShowing(false)}>Got it</SuccessButton>
                    </div>
                </>
            )}
        </LoadingOverlay>
    );
};

export default CodeActivator;
