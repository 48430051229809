import React from 'react';

function DownloadsPage() {
    return (
        <div>
            <h1>Downloads</h1>
            <p>
                This is still work in progress. However, once finished, you will be able to
                download the game from here with unmetered speed.
            </p>
            <div className={'d-flex justify-content-center align-items-center mt-5'}>
                <div className={'w-50'}>
                    <img src={'/images/under-construction.svg'} alt={'Under Construction'} width={'100%'}/>
                </div>
            </div>
        </div>
    );
}

export default DownloadsPage;
