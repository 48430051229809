import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { LoadingSpinner } from "../../../../_components/spinners";
import { DarkButton, PrimaryButton } from "../../../../_components/buttons";
import oauthClientService from "../../../../services/oauth/oauth-client.service";
import oauthService from "../../../../services/oauth/oauth.service";
import {OAuthClient} from "../../../models/oauth/IOAuthClient";

const AuthenticateApp: React.FC = () => {
    const location = useLocation();

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [oauthClient, setOauthClient] = useState<OAuthClient | null>(null);

    const queryParams = new URLSearchParams(location.search);
    const responseType = queryParams.get('response_type');
    const clientId = queryParams.get('client_id');
    const redirectUri = queryParams.get('redirect_uri');

    useEffect(() => {
        const validateAndFetchScopes = async () => {
            const supportedResponseTypes = ['code', 'token'];
            if (!responseType || !supportedResponseTypes.includes(responseType)) {
                setError('Unsupported response type.');
                setLoading(false);
                return;
            }

            if (!clientId || !redirectUri) {
                setError('Missing required query parameters.');
                setLoading(false);
                return;
            }

            try {
                const clientData = await oauthClientService.get(clientId);

                setOauthClient(clientData);
                setLoading(false);
            } catch (err) {
                console.error('Error fetching scopes:', err);
                setError('An unexpected error occurred while fetching scopes.');
                setLoading(false);
            }
        };

        validateAndFetchScopes().then();
    }, [clientId, responseType, redirectUri]);

    const handleDeny = () => {
        setError('Access denied by the user.');
        setOauthClient(null);
    };

    const handleAllow = async () => {
        if (!clientId || !responseType || !redirectUri) {
            setError('Missing required parameters for authorization.');
            return;
        }

        try {
            if(!oauthClient) {
                setError('An unexpected error occurred while authorizing the app: Client data unavailable.');
                return;
            }

            const tokenData = await oauthService.authorize(clientId, responseType);

            const redirectParams = new URLSearchParams({
                access_token: tokenData.access.token,
                access_token_expiry: tokenData.access.expire,
                refresh_token: tokenData.refresh.token,
                refresh_token_expiry: tokenData.refresh.expire,
                redirect_url: `${window.location.origin}/oauth/success?app_name=${encodeURIComponent(oauthClient.name)}`,
                app_name: oauthClient.name,
            });

            window.location.href = `${redirectUri}?${redirectParams.toString()}`;
        } catch (err: any) {
            if(err.response && err.response.data && err.response.data.message) {
                console.error('Error authorizing:', err.response.data);
                setError(`Error: ${err.response.data.message}`);
            }
            else {
                console.error('Error authorizing:', err);
                setError('An unexpected error occurred while authorizing the app.');
            }
        }
    };

    return (
        <div className="d-flex justify-content-center align-items-center vh-100">
            <div className="card p-4 shadow-lg bg-glass card-sm">
                {loading && <LoadingSpinner />}
                {error && <div className="alert alert-danger">{error}</div>}
                {oauthClient && !loading && (
                    <OAuthForm appData={oauthClient} handleAllow={handleAllow} handleDeny={handleDeny} />
                )}
            </div>
        </div>
    );
};

interface OAuthFormProps {
    appData: OAuthClient;
    handleAllow: () => void;
    handleDeny: () => void;
}

const OAuthForm: React.FC<OAuthFormProps> = ({ appData, handleAllow, handleDeny }) => {
    return (
        <>
            <p className="text-center">
                <strong>{appData.name}</strong> wants to:
            </p>

            <div className="container mb-4">
                {appData.explanations.map((explanation, index) => (
                    <div key={index} className="row align-items-center p-1">
                        <div
                            className="col-1 d-flex justify-content-center align-items-center"
                            style={{ height: '2em' }}
                        >
                            <i className="fa fa-angle-right text-secondary" style={{ fontSize: '1em' }}></i>
                        </div>
                        <div className="col-11 d-flex align-items-center">
                            <span>{explanation}</span>
                        </div>
                    </div>
                ))}
            </div>

            <div className={'d-flex gap-2'}>
                <DarkButton className="flex-grow-1" onClick={handleDeny}>
                    Deny
                </DarkButton>
                <PrimaryButton className="flex-grow-1" onClick={handleAllow}>
                    Allow
                </PrimaryButton>
            </div>
        </>
    );
};

export default AuthenticateApp;
