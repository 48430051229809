import * as yup from 'yup';
import Subscription from "./Subscription";

export interface IUser {
    _id: string;
    email: string;
    subscription: Subscription | null;
    created?: Date;
    updated?: Date;
}

export default class User implements IUser {
    _id: string;
    email: string;
    subscription: Subscription | null;
    created?: Date;
    updated?: Date;

    public static readonly schema = yup.object().shape({
        _id: yup.string().required(),
        email: yup.string().email().required(),
        subscription: Subscription.schema.nullable().default(null),
    });

    constructor(user: IUser) {
        console.log(`Creating user: ${JSON.stringify(user)}`);
        User.schema.validateSync(user);

        this._id = user._id;
        this.email = user.email;
        this.subscription = user.subscription;
    }
}
