import React from 'react';

function Overview() {
    return (
        <div>
            <h1>Overview</h1>
            <p>
                I am a bit unsure still what there will be on this page. Maybe I will summarize "Changelog", "Downloads"
                and "My Subscription" in here so that you can get a quick look at everything.
            </p>
            <div className={'d-flex justify-content-center align-items-center mt-5'}>
                <div className={'w-50'}>
                    <img src={'/images/under-construction.svg'} alt={'Under Construction'} width={'100%'}/>
                </div>
            </div>
        </div>
    );
}

export default Overview;
