import React, {useEffect, useState} from 'react';
import LinkedAccountsContext from "./LinkedAccountsContext";
import platformsService from "../../../../../services/platforms.service";
import {ILinkedAccount} from "../../../../models/dto/LinkedAccount";

function LinkedAccountsProvider({ children }: { children: React.ReactNode }) {
    const [linkedAccounts, setLinkedAccounts] = useState<ILinkedAccount[] | null>(null);

    async function reload() {
        platformsService.getLinkedAccounts().then((accounts) => {
            setLinkedAccounts(accounts);
        });
    }

    useEffect(() => {
        if(linkedAccounts === null) {
            reload().then();
        }
    }, [linkedAccounts]);

    return (
        <LinkedAccountsContext.Provider value={{
            linkedAccounts,
            reload
        }}>
            { children }
        </LinkedAccountsContext.Provider>
    );
}

export default LinkedAccountsProvider;