import ILoginDto from "../app/models/dto/LoginDto";
import IRegisterDto from "../app/models/dto/RegisterDto";
import User from "../app/models/User";
import axios, {AxiosResponse} from "axios";
import authAxios from "./util/authAxios";

class AuthService {
    async login(data: ILoginDto): Promise<AxiosResponse<User>> {
        return axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/auth/login`,
            data,
            { withCredentials: true }
        );
    }

    async register(data: IRegisterDto): Promise<AxiosResponse<User>> {
        return axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/auth/register`,
            data,
            { withCredentials: true }
        );
    }

    async getAccessToken(): Promise<AxiosResponse<User>> {
        return axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/auth/accessToken`,
            { withCredentials: true }
        );
    }

    async logout(): Promise<boolean> {
        try {
            await Promise.all([
                axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/clear/refreshToken`, null, { withCredentials: true }),
                axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/clear/accessToken`, null, { withCredentials: true })
            ]);
        } catch(err: any) {
            console.error(err);
            return false;
        }
        return true;
    }

    async getProfile(): Promise<User> {
        const response = await authAxios.get<User>(
            `${process.env.REACT_APP_API_BASE_URL}/auth/profile`
        );
        console.log(response);
        return new User(response.data);
    }
}

const authService = new AuthService();

export default authService;