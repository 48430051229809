import React from 'react';
import {PrimaryButtonSmall} from "../../../../_components/buttons";
import {useUser} from "../../../modules/auth";
import {anonymizeEmail, capitalize} from "../../../../_components/util";
import useLinkedAccounts from "../linked-accounts/provider";
import './CurrentSubscriptionCard.scss';
import formatDate from "../../../../_components/util/formatDate";

function CurrentSubscriptionCard() {
    const user = useUser();
    const subscription = user ? user.subscription : null;
    const linkedAccounts = useLinkedAccounts();
    const linkedAccount = linkedAccounts ? linkedAccounts.find(account => account.id === subscription?.sourceRef) || null : null;

    return (
        <div className={'card'}>
            <div className={'card-header'}>
                <div className={'d-flex align-items-center'}>
                    <div className={'flex-grow-1'}>Current Subscription</div>
                    <div>
                        { user?.subscription?.source === 'patreon' &&
                            <PrimaryButtonSmall onClick={
                                () => window.open('https://www.patreon.com/settings/memberships/sirmister')
                            }>
                                <span className={'mx-1'}>
                                    <i className="fa-solid fa-external-link small"></i>
                                </span>
                                Manage on Patreon
                            </PrimaryButtonSmall>
                        }
                    </div>
                </div>
            </div>
            <div className={'card-body'}>
                {subscription === null && <p>Currently, you do not have an active subscription.</p>}
                {subscription !== null &&
                    <div>
                        <div className={'d-flex flex-column align-items-center'}>
                            <span><strong>{subscription.tier} Tier</strong></span>
                            {subscription.imageUrl &&
                                <img src={subscription.imageUrl} alt={'Subscription Tier'} className={'w-50'}/>}
                            <p className={'fst-italic'}>expires&nbsp;
                                {formatDate(subscription.expires)}*
                            </p>
                            {linkedAccount && <span>Imported
                                from your {capitalize(subscription.source)}-Account
                                ({anonymizeEmail(linkedAccount.email)}).
                            </span>}
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

export default CurrentSubscriptionCard;
