import React, {useMemo, useState} from 'react';
import  {ILinkedAccount} from "../../../../models/dto/LinkedAccount";
import './LinkedAccount.scss';
import {DangerButtonSmall} from "../../../../../_components/buttons";
import platformsService from "../../../../../services/platforms.service";
import {useLinkedAccountsReload} from "../provider";
import {toast} from "react-toastify";
import {anonymizeEmail} from "../../../../../_components/util";

interface IProps {
    data: ILinkedAccount
}

function LinkedAccount({ data }: IProps) {
    const [showFullEmail, setShowFullEmail] = useState<boolean>(false);

    const iconUrl = useMemo(() => {
        switch (data.type) {
            case 'patreon':
                return '/icons/commercial/patreon.png';
            case 'subscribestar':
                return '/icons/commercial/subscribestar.png';
            default:
                return '';
        }
    }, [data]);

    return (
        <div className={'linked-account-item'}>
            <img src={iconUrl} alt={data.type} style={{height: '2em'}}/>
            <span className={'w-100 clickable'} onClick={() => setShowFullEmail(!showFullEmail)}>
                {!showFullEmail && anonymizeEmail(data.email)}
                {showFullEmail && data.email}
            </span>
            <div>
                <UnlinkButton type={data.type} id={data.id} />
            </div>
        </div>
    );
}

function UnlinkButton({ type, id }: { type: string, id: string }) {
    const reload = useLinkedAccountsReload();
    const [loading, setLoading] = useState<boolean>(false);

    async function onUnlink() {
        setLoading(true);

        await toast.promise(platformsService.unlinkAccount(type, id), {
            pending: 'Unlinking...',
            success: 'Removed connection from your account',
            error: 'Failed to unlink',
        });

        await reload();
        setTimeout(() => setLoading(false), 1000);
    }

    return (
        <DangerButtonSmall
            onClick={onUnlink}
            disabled={loading}
        >
            { !loading
                ? 'Unlink'
                : 'Unlinking...'
            }
        </DangerButtonSmall>
    );

}


export default LinkedAccount;
