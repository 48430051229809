import React, {createContext} from "react";

export enum Theme {
    LIGHT = 'light',
    DARK = 'dark'
}

export enum DeviceTheme {
    LIGHT = 'light',
    DARK = 'dark'
}

export interface ITheme {
    theme: Theme;
    deviceTheme: DeviceTheme;
    setTheme: (theme: Theme) => void;
}

const ThemeContext = createContext<ITheme | undefined>(undefined);

export function useTheme() {
    const context = React.useContext(ThemeContext);
    if (context === undefined) {
        throw new Error('useTheme must be used within a ThemeProvider');
    }
    return context;
}

export default ThemeContext;