import React from 'react';
import CodeActivator from "../code-activator/CodeActivator";
import LinkedAccountsCard from "../linked-accounts/LinkedAccountsCard";
import {LinkedAccountsProvider} from "../linked-accounts/provider";
import CurrentSubscriptionCard from "../current-subscription-card/CurrentSubscriptionCard";

function SubscriptionsPage() {
    return (
        <div>
            <LinkedAccountsProvider>
                <CodeActivator/>

                <h1>Your Subscriptions</h1>
                    <div className="row">
                        <div className="col-12 col-md-6 mb-4">
                            <CurrentSubscriptionCard />
                        </div>
                        <div className="col-12 col-md-6 mb-4">
                            <LinkedAccountsCard/>
                        </div>
                </div>
                {/*<div className="row">*/}
                {/*    <div className="col-12">*/}
                {/*        <Card header={'Subscription History'}>*/}
                {/*            <p>You have never been a member.</p>*/}
                {/*        </Card>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </LinkedAccountsProvider>
        </div>
);
}

export default SubscriptionsPage;
