import React, { forwardRef } from 'react';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    className?: string;
    variant: string;
    size?: string;
}

const BootstrapButton = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
    let { className, variant, size, children, ...rest } = props;

    let height = '2.5em';
    if(size === 'lg') height = '3em';
    if(size === 'sm') height = '2em';

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'bottom',
                height,
                width: '100%',
            }}
            className={className || ''}
        >
            {/* Forward all button props and ref */}
            <button
                ref={ref}
                {...rest}
                className={`btn btn-${variant}${size ? ` btn-${size}` : ''} flex-grow-1 ${className || ''}`}
            >
                {children}
            </button>
        </div>
    );
});

BootstrapButton.displayName = 'PrimaryButton';

export default BootstrapButton;
